<template>
    <div class="d-flex flex-column align-center">
        <h3>{{ $t(`landing.carouserTitle`) }}</h3>
        <v-sheet
            class="mx-auto mt-2"
            :max-width="isMobile ? '100%' : maxWidth"
            color="appBackground"
        >
            <v-slide-group
                v-model="hero"
                mandatory
                class="pa-4"
                center-active
                show-arrows
                style="height: 280px;"
            >
                <template v-slot:prev>
                    <v-btn icon depressed color="primary" class="mr-6">
                        <v-icon color="white">
                            fa-solid fa-arrow-left
                        </v-icon>
                    </v-btn>
                </template>
                <template v-slot:next>
                    <v-btn icon depressed color="primary" class="ml-6">
                        <v-icon color="white">
                            fa-solid fa-arrow-right
                        </v-icon>
                    </v-btn>
                </template>
                <v-slide-item
                    v-for="(item, index) in heroes"
                    :key="index"
                    v-slot="{ active, toggle }"
                    :value="item.type"
                >
                    <v-card
                        :color="active ? 'primary' : 'primary lighten-4'"
                        class="ma-5 d-flex"
                        height="200"
                        width="200"
                        flat
                        @click="toggle"
                    >
                        <v-card-text class="d-flex justify-center">
                            <v-img
                                alt="App Logo"
                                class="shrink"
                                contain
                                :src="
                                    require(`@/assets/heroes/${item.type}.svg`)
                                "
                                width="140"
                                transition="scale-transition"
                            ></v-img>
                        </v-card-text>
                    </v-card>
                </v-slide-item>
            </v-slide-group>
        </v-sheet>
    </div>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getHeroes, setHero } from "@/api/chatbotApi.js";
import { mapGetters, mapState } from "vuex";

export default {
    name: "SelectHero",

    props: {
        isMobile: {
            required: false,
            type: Boolean,
        },
        maxWidth: {
            required: true,
            type: String,
        },
        selectedHero: {
            required: false,
            type: String,
            default: "",
        },
    },

    data() {
        return {
            heroes: [],
            hero: "",
        };
    },

    computed: {
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
    },

    methods: {
        async handleHeroes() {
            const { response, error } = await withAsync(getHeroes);

            if (error) {
                return;
            }
            this.heroes = response.data.data;
            this.hero = this.heroes[0].type;
        },

        async selectHero() {
            let url = `${this.user_role}/chatbot/update`;

            if (!this.isLoggedIn) {
                this.$store.dispatch("auth/setHero", this.hero);
                return;
            }
            const payload = {
                chatbot_char: this.hero,
            };
            const { response, error } = await withAsync(setHero, payload, url);

            if (error) {
                return;
            }

            this.$store.dispatch("auth/setHero", response.data.data.type);
        },
    },

    watch: {
        hero(newVal) {
            if (newVal) this.$emit("update:selectedHero", newVal);
        },
    },

    created() {
        this.handleHeroes();
    },
};
</script>

<style lang="scss" scoped></style>
